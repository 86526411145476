import React from "react"
import ListeGabarit from "./modèles/liste"
import {graphql} from "gatsby";

export const pageQuery = graphql`
    query($id: String!) {
        page: mdx(id: {eq: $id}) {
            ...ListePageContent
        }
    }
`

const MirlopediaGabarit = (props) => <ListeGabarit {...props}/>

export default MirlopediaGabarit
